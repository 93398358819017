import { mainAxios as axios } from '../utils/axios';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useEffect } from 'react';
// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/auth/Login';
// components
import {useSelector} from '../redux/store';
import LoadingScreen from '../components/loading-screen';
import { PATH_AUTH } from '../routes/paths';
import { SERVER_ADDRESS } from '../config';
import { multiFactor } from '@firebase/auth';
import { AUTH } from 'src/contexts/FirebaseContext';
// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  // const {firstLogin} = useSelector((state)=> state.userInfo);
  // const [mfUser, setMfUser] = useState();
  const { pathname } = useLocation();
  const [sessionState, setSessionState] = useState('loading');
  const currentUser = AUTH.currentUser;
  const [requestedLocation, setRequestedLocation] = useState(null);

  useEffect(()=>{
    return(()=>{})
  },[currentUser])


  if (!isInitialized) {
    return <LoadingScreen />;
  }

  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
      return;
    }
    return <Login />;
  } else{
    console.log(currentUser)
    let page = <LoadingScreen/>
    
    if(pathname !== "/api-keys/created-key"){
      localStorage.removeItem("keyName");
      localStorage.removeItem("keyExpire");
    }

    if(currentUser !== undefined && currentUser !== null){
      let mfUser = multiFactor(currentUser)
      console.log(currentUser)
      console.log(mfUser)
      let isEnabled = (mfUser.enrolledFactors.length !== 0);
      // let error = false;
      console.log(isEnabled)
      if(isEnabled === false){
        page = <Navigate to={PATH_AUTH.verifyPhoneNumber} />
      }else if ( isEnabled === true){
  
        if (requestedLocation && pathname !== requestedLocation) {
          setRequestedLocation(null); 
          page = <Navigate to={requestedLocation} />;
        }
        page =  <>{children}</>;
      }
    } else {
      page = <LoadingScreen/>
    }
    return page;
  }
      
}
