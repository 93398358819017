import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';
// layouts
import MainLayout from '../layouts/main';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
// components
import LoadingScreen from '../components/loading-screen';
import { useSelector } from '../redux/store';
import RoleBasedGuard from 'src/guards/RoleBasedGuard';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  return (
    <Suspense fallback={<LoadingScreen isDashboard={pathname.includes('/dashboard')} />}>
      <Component {...props} />
    </Suspense>
  );
};


export default function Router() {
  const {firstLogin} = useSelector((state)=>state.userInfo);
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'reset-password-sent', element: <ResetPasswordSent />},
        { path: 'new-password', element: <NewPassword /> },
        { 
          path: 'verify', 
          element: (
            <GuestGuard>
              <VerifyCode />
            </GuestGuard>
            ) },
        { path: 'register-success', element: <RegisterSuccess />},
        { path: 'email-link', element: <EmailLink />},
        { path: 'verify-phone-number', element: <VerifyPhoneNumberContainer/>}
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: 
            <Navigate to={PATH_AFTER_LOGIN} /> 
        },
        { path: 'app', element: <GeneralApp /> },
      ],
    },
    {
      path: 'miner-group',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={['Admin', 'Validator']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to='/minger-group/all'/> },
        { path: 'all', element: <MinerGroups />},
        { path: 'create', element: <CreateMinerGroups />},
        // { path: 'join', element: <JoinMinerGroups />},
        { path: 'groupCreated', element: <MinerGroupCreated />},
        { path: 'groupInfo', element: <MinerGroupInfo />},
      ]
    },
    {
      path: 'validator-group',
      element: (
        <AuthGuard>
            <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { 
          path: '', element: (
            <RoleBasedGuard roles={['Admin','Miner']}>
              <Navigate to='/validator-group/all'/> 
            </RoleBasedGuard>
        )},
        { path: 'all', element: (
          <RoleBasedGuard roles={['Admin','Miner']}>
            <ValidatorGroups />
          </RoleBasedGuard>
        )},
        { path: 'create', element:(
          <RoleBasedGuard roles={['Admin']}>
            <CreateValidatorGroups />
          </RoleBasedGuard>
        )},
        // { path: 'join', element: <JoinValidatorGroups />},
        { path: 'groupCreated', element:(
          <RoleBasedGuard roles={['Admin']}>
            <ValidatorGroupCreated />
          </RoleBasedGuard>
        )},
        { path: 'groupInfo', element:(
          <RoleBasedGuard roles={['Admin', 'Miner']}>
             <ValidatorGroupInfo />
          </RoleBasedGuard>
        )}
      ]
    },
    {
      path: 'projects',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={['Miner', 'Validator', 'Admin']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to='/projects/all'/> },
        { path: 'all', element: <Projects />},
        { path: 'create', element: <CreateProject />},
        { path: 'projectCreated', element: <ProjectCreated />},
        { path: 'projectInfo', element: <ProjectInfo/>},
        { path: 'projectIntervalUpload', element: <ProjectIntervalUpload/>},
        { path: 'validateInterval', element: <ProjectValidateInterval/>},
        { path: 'projectPayoutSplits', element: <ProjectPayoutSplits/>},
        { path: 'projectPayoutApproval', element: <ProjectPayoutApproval/>},
        { path: 'projectApproval', element: <ProjectApproval/>},
        { path: 'createProjectDisclaimer', element: <CreateProjectDisclaimer/>}, 
      ]
    },
    {
      path: 'api-keys',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={['Miner', 'Validator']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to='/api-keys/all'/> },
        { path: 'all', element: <ApiKeys/>},
        { path: 'create-key', element: <ApiKeyCreate/>},
        { path: 'created-key', element: <ApiKeyCreated/>}
      ]
    },
    {
      path: 'admin',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={['Admin']}>
            <DashboardLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to='/admin/all'/>},
        { path: 'all', element: <Admin />},
      ]
    },
    {
      path: 'manage-funds',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to='/manage-funds/balance'/> },
        { path: 'balance', element: <ManageFunds />},
      ]
    },
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <Page404 /> },
        { path: '403', element: <Page403 /> },
        { path: '*', element: <Navigate to="/404" replace /> },
        { path: 'public-record', element: <PublicRecord/>},
        {path: 'public-record/info', element: <PublicRecordInfo/>},
      ],
    },
    {
      path: '/',
      element: <Navigate to="/auth/login" replace />
      // element: (
      //         <GuestGuard>
      //           <Login />
      //         </GuestGuard>
      //       ),
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// AUTHENTICATION
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const EmailLink = Loadable(lazy(() => import('../pages/auth/EmailLink')));
const VerifyPhoneNumberContainer = Loadable(lazy(()=> import('../pages/auth/VerifyPhoneNumberContainer')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const ResetPasswordSent = Loadable(lazy(()=> import('../pages/auth/ResetPasswordSent')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
const RegisterSuccess = Loadable(lazy(() => import('../pages/auth/RegisterSuccess')));
// DASHBOARD

// GENERAL
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));

const ManageFunds = Loadable(lazy(()=> import('../pages/dashboard/ManageFunds')));

//API KEY
const ApiKeys = Loadable(lazy(()=> import('../pages/api-key/ApiKeys.js')))
const ApiKeyCreate = Loadable(lazy(()=> import('../pages/api-key/ApiKeyCreate.js')))
const ApiKeyCreated = Loadable((lazy(()=> import('../pages/api-key/ApiKeyCreated.js'))))
// PROJECTS
const Projects = Loadable(lazy(()=> import('../pages/project/Projects')));
const CreateProject = Loadable(lazy(()=> import('../pages/project/create-project/CreateProjectPage')));
const ProjectCreated = Loadable(lazy(()=> import('../pages/project/ProjectCreated')));
const ProjectInfo = Loadable(lazy(()=> import('../pages/project/ProjectInfo')));
const ProjectValidateInterval = Loadable(lazy(()=>import('../pages/project/ValidateInterval')));
const ProjectIntervalUpload = Loadable(lazy(()=> import('../pages/project/ProjectIntervalInfoUpload')));
const ProjectPayoutSplits = Loadable(lazy(()=> import('../pages/project/ProjectPayoutSplits')));
const ProjectPayoutApproval = Loadable(lazy(()=> import('../pages/project/ProjectPayoutApproval')));
const ProjectApproval = Loadable(lazy(() => import('../pages/project/ProjectApproval')));
const CreateProjectDisclaimer = Loadable(lazy(()=>import('../pages/project/create-project/CreateProjectDisclaimer')));
// const ProjectIntervalInfo = Loadable(laxy(()=>import('..pages/project/')))
// VALIDATOR
const ValidatorGroups = Loadable(lazy(()=> import('../pages/validator/ValidatorGroups')));
const JoinValidatorGroups = Loadable(lazy(()=> import('../pages/validator/JoinValidatorGroups')));
const CreateValidatorGroups = Loadable(lazy(()=> import('../pages/validator/CreateValidatorGroups')));
const ValidatorGroupCreated = Loadable(lazy(()=>import('../pages/validator/ValidatorGroupCreated')));
const ValidatorGroupInfo = Loadable(lazy(()=> import('../pages/validator/ValidatorGroupInfo')));

// MINER
const MinerGroups = Loadable(lazy(()=> import('../pages/miner/MinerGroups')));
const JoinMinerGroups = Loadable(lazy(()=> import('../pages/miner/JoinMinerGroups')));
const CreateMinerGroups = Loadable(lazy(()=> import('../pages/miner/CreateMinerGroups')));
const MinerGroupCreated = Loadable(lazy(()=>import('../pages/miner/MinerGroupCreated')));
const MinerGroupInfo = Loadable(lazy(()=> import('../pages/miner/MinerGroupInfo')));

const Admin = Loadable(lazy(()=> import('../pages/admin/Admin')));

// TEST RENDER PAGE BY ROLE
const PermissionDenied = Loadable(lazy(() => import('../pages/dashboard/PermissionDenied')));

// MAIN
const HomePage = Loadable(lazy(() => import('../pages/Home')));
const About = Loadable(lazy(() => import('../pages/About')));
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const Page403 = Loadable(lazy(() => import('../pages/Page403')));
const Page404 = Loadable(lazy(() => import('../pages/Page404')));
const PublicRecord = Loadable(lazy(()=> import('../pages/public-record/PublicRecordSearch')));
const PublicRecordInfo = Loadable(lazy(()=> import('../pages/public-record/PublicRecordInfo')));