import { useContext } from 'react';
//
import { RecaptchaVerifier } from 'firebase/auth';

// import { AuthContext } from '../contexts/JWTContext';
//  import { AuthContext } from '../contexts/Auth0Context';
 import { AuthContext, AUTH } from '../contexts/FirebaseContext';
// import { AuthContext } from '../contexts/AwsCognitoContext';

// ----------------------------------------------------------------------

const useAuth = () => {
 

  const context = useContext(AuthContext);
  if (!context) throw new Error('Auth context must be use inside AuthProvider');

  return context;
};

export default useAuth;
