import { mainAxios as axios } from '../utils/axios';
import PropTypes from 'prop-types';
import { Navigate, useLocation } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import {useSelector} from '../redux/store';
import { PATH_DASHBOARD, PATH_AUTH } from '../routes/paths';
import { SERVER_ADDRESS } from '../config';
import LoadingScreen from '../components/loading-screen';
import Login from '../pages/auth/Login';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node,
};


export default function GuestGuard({ children }) {
  const { isAuthenticated, isInitialized } = useAuth();
  const {firstLogin} = useSelector((state)=> state.userInfo);
  const { pathname } = useLocation();
  const [sessionState, setSessionState] = useState('loading');
  console.log('isAuthenticated ', isAuthenticated);
  console.log('isInitialized', isInitialized);
  

  if (isAuthenticated) {
        return <Navigate to={PATH_DASHBOARD.general.app} />;
  }

  else{
    localStorage.removeItem("keyName");
    localStorage.removeItem("keyExpire");
    if(pathname !== '/auth/verify'){
      localStorage.removeItem('mfaRequiredError');
      localStorage.removeItem('verificationId');
      localStorage.removeItem('loggingIn')
    }
    if(pathname === '/auth/verify' && localStorage.getItem('loggingIn') !== 'yes'){
      localStorage.removeItem('mfaRequiredError');
      localStorage.removeItem('verificationId');
      localStorage.removeItem('loggingIn')
      return <Navigate to={PATH_AUTH.login} />
    }
    
    let page = <LoadingScreen />;
    
    page = <>{children}</> 
    return page;
  }

}
