import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
// import { useNavigate } from 'react-router';
import { initializeApp } from 'firebase/app';
import {
  getAuth,
  getIdToken,
  signOut,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendEmailVerification,
  applyActionCode,
  RecaptchaVerifier,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  multiFactor,
  AuthErrorCodes,
  getMultiFactorResolver,
  signInWithCustomToken,
  updateCurrentUser,
} from 'firebase/auth';
import { mainAxios as axios, firstPartyAxios } from '../utils/axios';
import { PATH_AUTH, PATH_DASHBOARD } from '../routes/paths';
//
import { FIREBASE_API, SERVER_ADDRESS } from '../config';
import { setUserEmail,setFirstLogin } from '../redux/slices/userInfo';
// ----------------------------------------------------------------------

const ADMIN_EMAILS = ['demo@minimals.cc'];

const firebaseApp = initializeApp(FIREBASE_API);

export const AUTH = getAuth(firebaseApp);


const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};


const actionCodeSettings = {
  // URL you want to redirect back to. The domain (www.example.com) for
  // this URL must be whitelisted in the Firebase Console.
  url: 'http://localhost:3030',
  // This must be true for email link sign-in.
  // handleCodeInApp: true,
  // iOS: {
  //   bundleId: 'com.example.ios',
  // },
  // android: {
  //   packageName: 'com.example.android',
  //   installApp: true,
  //   minimumVersion: '12',
  // },
  // FDL custom domain.
  dynamicLinkDomain: 'coolapp.page.link',
};

const reducer = (state, action) => {
  if (action.type === 'INITIALISE') {
    const { isAuthenticated, user } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
    };
  }

  return state;
};

const AuthContext = createContext({
  ...initialState,
  method: 'firebase',
  loginWithToken: () => Promise.resolve(),
  loginWithEmailPassword: () => Promise.resolve(),
  register: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  resendEmail: () => Promise.resolve(),
  getCurrentUser: ()=>Promise.resolve(),
  verifyPhoneNumber: ()=> Promise.resolve(),
  getPhoneAuthProvider: ()=>Promise.resolve(),
  getUserId: ()=>Promise.resolve(),
  setUserAuth: ()=>Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  // const navigate = useNavigate();

  const [profile, setProfile] = useState(null);
  const [currRole, setRole] = useState(null);
  useEffect(
    // sets the function that runs whenever the auth state of the user changes.
    () =>{
      onAuthStateChanged(AUTH, async (user) => {
        console.log(user)
        if (user !== undefined && user !== null && user.emailVerified) {
            let token = await getUserIdToken();
            axios.post('/userRole', {token:token})
            .then((resp)=>{
              if(resp.data !== 'error'){
                console.log(resp)
                setRole(resp.data.role[0]);
                dispatch({
                  type: 'INITIALISE',
                  payload: { isAuthenticated: true, user },
                });        
              }
            })
        } else {
          setRole(null)
          dispatch({
            type: 'INITIALISE',
            payload: { isAuthenticated: false, user: null },
          });
        }
      })
    }, [dispatch]);

  const handleUserLogin = (userCredentials) => {
      // A helper function that I call after any of the firebase login functions
      // authenticates the user.
      const user = userCredentials.user;
      const uid = user.uid;
      
      // If the users email is not verified sign them out of the client side firebase api
      // and return a Email Not Verified error that the user sees.
      if(!user.emailVerified){
        logout();
        // setUserAuth(false, null);
        throw new Error('Email Not Verified');
      }
      
  }

  const setUserAuth = (state, user)=>{
      dispatch({
        type: 'INITIALISE',
        payload: { isAuthenticated: state, user },
      }); 
  } 

  const handleUserLoginErrors = (error)=>{
    // Handles all the error handling for login
    var retError = Error();
    if(error.code === AuthErrorCodes.INVALID_PASSWORD){
      retError = Error('The password or email address entered is incorrect. Please try again.');
    } else if(error.code === AuthErrorCodes.MFA_REQUIRED){
        try{
          retError = Error('No error');
          const jsonError = JSON.stringify(error);
          localStorage.setItem('mfaRequiredError', jsonError);
          const resolver = getMultiFactorResolver(AUTH, error);
          // window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {'size':'invisible'}, AUTH);
          const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
          };
          const provider = new PhoneAuthProvider(AUTH);
          // const serverAddr = `/createVerifyId`;
          provider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
          .then((verificationId) =>{
            // firstPartyAxios.post(serverAddr, {})
            // .then((resp)=>{
            //   console.log(resp)
            //   const vid = resp.data.vid
              localStorage.setItem('verificationId', verificationId);
              localStorage.setItem('loggingIn','yes');
            //   console.log(verificationId);
              window.location.href = PATH_AUTH.verify //+ `?vid=${vid}`;
              // navigate(`/verify?vid=${vid}`)
            // })
            // .catch((error)=>{
            //   console.log(error);
            //   retError = Error('A problem occured with connecting to the server. Try again later.');
            // });
          })
        } catch(errorHandlingError) {
            if(errorHandlingError.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER)
              retError = Error('Too many login attempts. Try Again later.');
        }
    } else if(error.code === AuthErrorCodes.USER_DELETED){
      retError = Error('The password or email address entered is incorrect. Please try again.')
    } else if(error.code === AuthErrorCodes.USER_DISABLED){
      retError = Error('This account has been disabled due to unusual activity. To enable this account again, contact support at xxx-xxx-xxxx');
    } else if(error.code === AuthErrorCodes.NETWORK_REQUEST_FAILED){
      retError = Error('Failed to contact the server. Check your internet connection and try again.')
    } else if(error.message === 'Email Not Verified') {
      retError = Error(error.message);
    } else{
      retError = Error('An error has occured please try agin after some time.')
    }
    return retError;
  }


  const loginWithEmailPassword = (email, password) => 
    new Promise((resolve, reject)=>{
      // const serverAddr = `/updateUserState`;
      try{

        signInWithEmailAndPassword(AUTH, email, password)
        .then((userCredentials)=>{
            handleUserLogin(userCredentials);
            resolve();
        })
        .catch((error)=>{
          console.log(error);
          reject(handleUserLoginErrors(error));          
        });
      } catch(error){
        reject(handleUserLoginErrors(error));          
        console.log(error);
      }
    });

  const register = (email, password, firstName, lastName, inviteCode) =>
    new Promise ((resolve,reject) =>{
      setUserEmail(email);
      const userInfo = {
        email,
        firstName,
        lastName,
        password,
        inviteCode
      }
      const registerAddr = `/register`
      console.log(registerAddr);
      console.log(userInfo);
      axios.post(registerAddr, userInfo)
      .then(res=>{
        console.log(res);
        console.log(res.data);
        console.log(res.data.result === 'error');
        if (res.data.result === 'success')
          window.location.href = PATH_AUTH.registerSuccess;
        else if (res.data.result === 'error')
          reject(new Error(res.data.message));
        // const uid = res.data.uid;
      })
      .catch(error=>{
        console.log(error);
      })
    });

  const resendEmail = (email) =>{
      axios.post(`/sendEmailVerifyLink`, {email})
      .then(res=>{
        console.log(res);
      })
    }

  const logout = () => 
    new Promise ((resolve, reject)=>{
      signOut(AUTH)
      try{
        updateCurrentUser(AUTH, null);
      } catch(error){
        console.log(error);
      }
      const serverAddr = `/logout`
      axios.post(serverAddr, {})
      .then((res)=>{
        console.log(res);
      })
      resolve();
    })

  const getCurrentUser = async () =>{
    const user = await AUTH.currentUser;
    return user;
  }

  const getPhoneAuthProvider = ()=>{
    return new PhoneAuthProvider(AUTH);
  }

  const getUserId =  async()=>{
    const user = await AUTH.currentUser;
    return user.uid;
  }

  async function getUserIdToken (){
    return new Promise(async (resolve, reject)=>{

      const user =  await AUTH.currentUser;
      user.getIdToken(true)
      .then((idToken)=>{
        resolve(idToken)  
      })
      .catch((error)=>{
        reject(error)
      })
    })
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'firebase',
        user: {
          id: state?.user?.uid,
          email: state?.user?.email,
          photoURL: state?.user?.photoURL || profile?.photoURL,
          displayName: state?.user?.displayName || profile?.displayName,
          role: currRole,
          phoneNumber: state?.user?.phoneNumber || profile?.phoneNumber || '',
          country: profile?.country || '',
          address: profile?.address || '',
          state: profile?.state || '',
          city: profile?.city || '',
          zipCode: profile?.zipCode || '',
          about: profile?.about || '',
          isPublic: profile?.isPublic || false,
        },
        loginWithEmailPassword,
        register,
        logout,
        resendEmail,
        getCurrentUser,
        getPhoneAuthProvider,
        setUserAuth,
        getUserId,
        getUserIdToken,
        auth:AUTH,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
