import axios from 'axios';
// config
import { HOST_API_KEY } from '../config';

// ----------------------------------------------------------------------

console.log(process.env.REACT_APP_ENVIRONMENT)
let url = 'https://api.network.mission6.io/dev/api'
if(process.env.REACT_APP_ENVIRONMENT == 'PRODUCTION'){
  url = 'https://api.network.mission6.io/api'
}
console.log(url)
const mainAxios = axios.create({ 
  baseURL: url,
  withCredentials: true
});

const firstPartyAxios = axios.create({
  baseURL: url,
  withCredentials: true
});

mainAxios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

firstPartyAxios.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export {mainAxios, firstPartyAxios};
