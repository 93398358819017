import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import {TextField } from '@mui/material'
// ----------------------------------------------------------------------

RHFDatePicker.propTypes = {
  name: PropTypes.string,
};

export default function RHFDatePicker({ name, sx, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
          {...field}
          
          // helperText={error?.message}
          {...other}
          renderInput={({ error: inputError, ...params}) => <TextField sx={sx} error={!!error} helperText={error?.message} {...params}/>}
        />
      )}
    />
  );
}
