// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import {ThemeSettings} from './components/settings';
import { StyledChart } from './components/chart';
import ScrollToTop from './components/scroll-to-top';
import { StyledProgressBar } from './components/progress-bar';
import SnackbarProvider from './components/snackbar';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
// ----------------------------------------------------------------------

export default function App() {
  return (
      <MotionLazyContainer>
        <ThemeProvider>
          <ThemeSettings>
            <SnackbarProvider>
              <StyledProgressBar />
              <StyledChart />
              <ScrollToTop />
              <Router />
            </SnackbarProvider>
          </ThemeSettings>
        </ThemeProvider>
      </MotionLazyContainer>
  );
}
