import { createSlice } from '@reduxjs/toolkit';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { dispatch } from '../store';

const initialState = {
    email: "",
    verifyType: "",
    phone: "",
    firstLogin: false,
}

const slice = createSlice({
    name: 'userInfo',
    initialState,
    reducers : {
        setEmail(state, email){
            state.email = email.payload;
            console.log('email passed is ',email);
            console.log('email stored is ', state.email);
        },

        setPhone(state, phone){
            state.phone = phone.payload;
        },

        increment(state){
            state.test1 += 1;
        },

        setVerify(state, type){
            console.log('code passed is ', type.payload);
            state.verifyType = type.payload;
        },

        setLogin(state, login){
            state.firstLogin = login.payload;
        }
    }
});

export default slice.reducer;

export const { setEmail, increment, setVerify, setPhone, setLogin} = slice.actions;


export function setUserEmail(email){
    dispatch(setEmail(email));
}

export function incrementTest(){
    dispatch(increment());
}

export function setVerifyType(type){
    dispatch(setVerify(type));
}

export function setUserPhone(phone){
    dispatch(setPhone(phone));
}

export function setFirstLogin(login){
    dispatch(setLogin(login));
}
