import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Link, Stack, Alert, IconButton, InputAdornment, Container, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// routes
import { RecaptchaVerifier } from 'firebase/auth';
import { PATH_AUTH } from '../../../routes/paths';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/iconify';
import FormProvider,{ RHFTextField, RHFCheckbox } from '../../../components/hook-form';
import {AUTH} from '../../../contexts/FirebaseContext';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { loginWithEmailPassword, resendEmail} = useAuth();

  const isMountedRef = useIsMountedRef();

  const [showPassword, setShowPassword] = useState(false);
  const [showSendEmail, setShowSendEmail] = useState(false);
  const [currentEmail, setCurrentEmail] = useState('');
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required'),
  });

  const defaultValues = {
    email: '',
    password: '',
    remember: true,
  };

  const methods = useForm({
    resolver: yupResolver(LoginSchema),
    defaultValues,
  });


  const SendEmailButton = ()=>{
    return (
      <>
        {
          showSendEmail && 
          <Button component="button" variant="subtitle2" onClick={(event) => {sendEmail(event)}}>
                    Resend email
          </Button>
        }
      </>
    )
  }

  function sendEmail(event){
    event.preventDefault();
    console.log(currentEmail);
    if(currentEmail !== '')
      resendEmail(currentEmail);
  }

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting, isSubmitSuccessful, isSubmitted },
  } = methods;

  const onSubmit = async (data) => {
    let prom = new Promise((resolve, reject)=>{
        console.log('clicked submit');
        console.log(data.email, data.password);
        console.log('trying')
        setShowSendEmail(false);
        try{
          loginWithEmailPassword(data.email, data.password).then((message)=>{
            // setIsSubmitting(true)
            console.log('success ', message);
          }).catch((error) => {
            if(error.message !== 'No error'){
              // setIsSubmitting(false)
              if(error.message === 'Email Not Verified')
              setShowSendEmail(true);
              setCurrentEmail(data.email);
              if (isMountedRef.current) {
                console.log('test');
                setError('afterSubmit', { ...error, message: error.message });
              }
              reject();
            } else{
              resolve();
            }
          })
        } catch(error){
          if(error.message !== 'No error'){
            // setIsSubmitting(false)
            if(error.message === 'Email Not Verified')
            setShowSendEmail(true);
            if (isMountedRef.current) {
              console.log('test');
              setError('afterSubmit', { ...error, message: error.message });
            }
            reject();
          } else {
            resolve();
          }
        }
      });
      return prom;
  };
  // TODO: 
  // clean this up and maybe replace try statement with a lazy and and suspense
  // then i could use the recaptcha button and have the lazy be a spinner
    useEffect(()=>{

      try{
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {'size':'invisible'}, AUTH);
      } catch (error){
        console.log(error);
      }
    },[])

  useEffect(()=>{
    console.log(errors);
  },[errors])
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing={3}>
        {!!errors.afterSubmit && 
          <Alert severity="error">
            {errors.afterSubmit.message}
            
            <SendEmailButton/>
          </Alert>
        }
        <RHFTextField name="email" label="Email address" />

        <RHFTextField
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/* <RHFCheckbox name="remember" label="Remember me" /> */}
        <Link component={RouterLink} variant="subtitle2" to={PATH_AUTH.resetPassword}>
          Forgot password?
        </Link>
      </Stack>

      <LoadingButton fullWidth id="recaptcha-container" size="large" type="submit" variant="contained" loading={isSubmitting || isSubmitSuccessful}>
        Login
      </LoadingButton>
      {/* <Container> */}
        <div id="recaptcha-container"/>
      {/* </Container> */}
    </FormProvider>
  );
}
